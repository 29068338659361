.loader-spinning {
  margin-right: 7px;
  vertical-align: -4px;
  animation: spin 1s infinite linear;
  color: #ff7f01;
  width: 100%;
  margin-top: 100px;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}
