@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: "Choplin Medium-DEMO";
  src: local('Choplin Medium-DEMO'), url(./assets/Choplin-Medium-DEMO.woff2) format('woff2');
}

html { font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Inter', sans-serif; }

@supports (font-variation-settings: normal) {
  html { font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Inter var', sans-serif; }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Inter', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #ededed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.profile-text-card {
  font-size: 14px;
}

a,
a:hover,
a:focus {
  color: #fa7319;
  font-weight: 500;
}

/* app margins (varies with navbar location, mobile/desktop) */
.App {
  margin-top: 51px;
  margin-bottom: 27px;
}

.App-sm {
  margin-top: 51px;
  margin-bottom: 77px;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

/* header */
.logo {
  background-image: linear-gradient(257deg, #eb650d 81%, #fc9842);
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: "Choplin Medium-DEMO";
  letter-spacing: 0.2px;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-default {
  height: 50px;
  box-shadow: 0 2px 5px 0 #dde4ee;
}

.navbar-brand {
  padding: 13px 15px;
}

.navbar-avatar {
  margin-right: 5px;
  margin-left: 10px;
  margin-top: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  background: #ced4da no-repeat 50%/cover;
}

.userdata {
  display: flex;
  align-items: center;
  margin-top: -6px;
  font-size: 15px;
  line-height: 13px;
  margin-left: auto;
}

.username {
  line-height: 15px;
  padding-top: 2px;
}

.login-btn {
  width: 38px;
  height: 20px;
  margin: 18px 20px 4px auto;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: center;
  color: #fa7319;
}

/* navbar */
.navbtn {
  top: 51px;
  min-height: 30px;
  background-color: #f8f8f8;
  font-size: 16px;
}

.navbtn-sm {
  min-height: 30px;
  background-color: #f8f8f8;
  font-size: 16px;
}

.navbar-nav {
  margin: 0;
}

.navbar-nav > li > a {
  padding: 4px 15px 0 !important;
  line-height: unset;
  margin-left: 5px;
  margin-top: 2px;
}

.navbar .nav > li:hover {
  background-color: #ececec !important;
  transition-property: background-color;
  transition-duration: 180ms;
  transition-timing-function: ease-out;
  border-radius: 3px;
}


.nav-icon {
  vertical-align: -4px;
}


.navbar .nav > li > a,
.navbar .nav > li > a:focus {
  background: none !important;
  color: #fcc17d !important;
}

.navbar .nav > .active > a > svg {
  color: #fa7319 !important;
}

.navbar .nav > .active > a::after {
  content: ' ';
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 54px;
  background: #fa7319;
  height: 4px;
  display: block;
  position: relative;
  top: 3px;
  left: -12px;
}

@media only screen and (max-width: 600px) {

  .navbar .nav > .active > a::after {
    content: ' ';
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 135%;
    margin-left: 8%;
    background: #fa7319;
    border-bottom: 3px solid #fa7319 !important;
    height: 4px;
    display: block;
    position: relative;
    top: 3px;
    left: -12px;
  }
}


a > svg {
  transform: scale(1.2);
  color: #fcc17d !important;
}

/* prevent collapse */
.navbar-collapse.collapse {
  display: block !important;
}

.navbar-nav > li,
.navbar-nav {
  float: left !important;
}

.navbar-nav.navbar-right:last-child {
  margin-right: -15px !important;
}

.navbar-right {
  float: right !important;
}

/* center navbar items for mobile */
.navbar-nav-sm {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-nav > li {
  flex-grow: 1;
  text-align: center;
  margin-top: 6px;
  width: 70px;
  height: 39px;
}

.navbar-nav-sm > li {
  width: 130px;
  text-align: center;
}

/* footer */
.footer {
  min-height: 22px;
  background-color: #f8f8f8;
  font-size: 14px;
}

.link {
  cursor: pointer;
  color: #333 !important;
  text-decoration: none !important;
}

/* overflow infinite scroll */
.infinite-scroll-component {
  overflow: unset !important;
}

/* button transitions */
button {
  transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}

/* make navbar border span entire width */
.container.navbar.navbar-default.navbar-fixed-top {
  width: 100%;
  background-color: white;
}

.container.navbar.navbar-default.navbar-fixed-bottom {
  width: 100%;
  background-color: white;
}

@media (min-width: 768px) {
  .container-fluid {
    width: 750px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    width: 970px !important;
    padding-right: 60px !important;
    padding-left: 60px !important;
  }

  .container {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    width: 1170px !important;
    padding-right: 120px !important;
    padding-left: 120px !important;
  }

  .container {
    padding-right: 120px !important;
    padding-left: 120px !important;
  }
}

.container.footer.navbar.navbar-default.navbar-fixed-bottom > .container-fluid {
  padding-top: 1px;
}

/* what the fuck, safari */
.row::after,
.row::before {
  display: none;
}

/* what the fuck, firefox */
button::-moz-focus-inner {
  border: 0;
}

.progress {
  background-color: #ccc;
}

.wrapperClass {
  border: 1px solid black;
}

.editorClass {
  padding: 0 10px 0 10px;
  margin-top: -10px;
  min-height: 100px;
}

.author-name {
  font-weight: 500;
}

/* fine... */
.h2,
h2 {
  font-size: 24px;
}

.panel-default {
  border-color: #bbb;
}

.panel {
  -webkit-box-shadow: 0 4px 5px 0 #dde4ee;
  box-shadow: 0 4px 5px 0 #dde4ee;
  border-radius: 10px;
  border: none;
}

.dropdown {
  display: flex;
}

.poll-answer-panel {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.poll-answer-panel-heading {
  background-color: unset !important;
  border-bottom: none;
  border-top: none;
}

.article-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  background: #ced4da no-repeat 50%/cover;
  vertical-align: -19px;
}

.comment-text {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
  width: calc(100% - 55px);
}

.question-text {
  display: table-cell;
  margin: 13px 32px 12px 8px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: black;
}

.question-num {
  width: 64px;
  height: 42px;
  margin: 16px 8px 0 0;
  padding: 4px 8px 8px 26px;
  background-image: linear-gradient(237deg, #eb650d 26%, #fc9842);
  color: white;
}

.question-num-text {
  width: 30px;
  height: 30px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.article-title {
  font-weight: 500;
  margin: 0;
  margin-bottom: 12px;
}

.article-tag {
  font-weight: unset;
  border-radius: 5px;
  margin-top: 15px;
  text-transform: uppercase;
}

.profile-text-card {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}

.vote-btn,
.vote-btn:active,
.vote-btn:focus,
.vote-btn:target,
.vote-btn:active:focus {
  border: 1px solid #ff7f01;
  background-color: #fff;
  color: #ff7f01;
  font-weight: 500;
  outline: none !important;
  box-shadow: none;
  padding-top: 7px;
  margin-top: 7px;
}

.vote-btn:hover {
  background-color: #ff7f01;
  color: #fff;
  font-weight: 500;
  padding-top: 7px;
  margin-top: 7px;
}

.content-spaced {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-btn,
.nav-btn:active, 
.nav-btn:focus, 
.nav-btn:target, 
.nav-btn:active:focus {
  border: 0 solid #ff7f01;
  background-color: #f8f8f8;
  color: #ff7f01;
  font-weight: 500;
  outline: none !important;
  box-shadow: none;
  margin-top: 4px;
  padding: 0;
}

.question-num {
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
}

.author-name {
  font-weight: bold;
}

.warning-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #414e62;
}

.poll-card-title {
  /* margin-top: auto;
  margin-bottom: auto; */
  margin: 8px 27px 0 9px;
  font-size: 18px;
  font-weight: 600;
}